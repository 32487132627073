import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TechnologyLogo from '../images/technology.svg'
import AiLogo from '../images/ai.svg'
import ClockLogo from '../images/clock.svg'
import AngularLogo from '../images/angular.svg'
import NodeLogo from '../images/Node.svg'
import JayYogeshwar from '../images/portfolio_jayyogeshwar.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import soniincorporation from '../images/soniincorporation.png'
import neighbourcare from '../images/neighbourcare.png'
import WordpressLogo from '../images/wordpress.svg'
import WoocommerceLogo from '../images/woocommerce_logo.svg'

import {Link } from 'gatsby'
export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
<section className="slick_slider_dots pt-8 pb-8 sm:pt-10 sm:pb-10 lg:pt-14 lg:pb-14 xl:min-h-screen relative">
<div className="container">
    <div className="titles pb-4 md:pb-10 xl:pb-12 text-center">
        <h3 className="pb-2 md:pb-2 lg:pb-3 xl:pb-3 2xl:pb-4">Our <strong>Portfolio</strong></h3>
        <p className="pt-1">Our certified developers and designers deliver a holistic and effective e-commerce website strategy, products and marketing tactics, design and usability, technology and security. This ensures that you achieve your sales and business goals.(Ex:
            Shopify, Magneto)</p>
    </div>
    <div className="-mx-4">
        <div className="portfolio_section flex flex-wrap items-center">
            <Slider 


           asNavFor={this.state.nav1}
           ref={slider => (this.slider2 = slider)}
           slidesToShow={1}
           slidesToScroll= {1}
           swipeToSlide={true}
           dots={true}
           focusOnSelect={true}
           arrows={false}
           fade={true}
           
           



           className="left_portfolio w-full sm:w-full md:w-left_portfolio-md lg:w-left_portfolio-lg xl:w-left_portfolio-xl 2xl:w-left_portfolio-2xl px-4">
                <div className="box py-4 md:pl-14 lg:pl-20 xl:pl-24 2xl:pl-32 ">
                    <div className="inner max-w-xs relative z-11 m-auto lg:m-0 lg:mr-auto">
                    <img className="lazyload" loading="lazy" data-src={JayYogeshwar} alt="portfolio"/>
                        <div className="shadows"></div>

                    </div>
                </div>
                <div className="box py-4 md:pl-14 lg:pl-20 xl:pl-24 2xl:pl-32 ">
                    <div className="inner max-w-xs relative z-11 m-auto lg:m-0 lg:mr-auto">
                    <img className="lazyload" loading="lazy" data-src={soniincorporation} alt="portfolio"/>
                        <div className="shadows"></div>

                    </div>
                </div>
                <div className="box py-4 md:pl-14 lg:pl-20 xl:pl-24 2xl:pl-32 ">
                    <div className="inner max-w-xs relative z-11 m-auto lg:m-0 lg:mr-auto">
                        <img className="lazyload" loading="lazy" data-src={neighbourcare} alt="portfolio"/>
                        <div className="shadows"></div>
                    </div>
                </div>
            </Slider>
            <Slider
                                asNavFor={this.state.nav2}
                                ref={slider => (this.slider1 = slider)}
                                fade={true}
                                slidesToShow={1}
                                slidesToScroll= {1}
    
             className="right_portfolio w-full sm:w-full md:w-right_portfolio-md lg:w-right_portfolio-lg xl:w-right_portfolio-xl 2xl:w-right_portfolio-2xl px-4 pt-6 md:pt-0">
                <div className="box">
                    <div className="inner">
                        <h4 className="pl-0 md:pl-6 lg:pl-16 xl:pl-20 pb-1 sm:pb-2 md:pb-3 lg:pb-3 xl:pb-3 2xl:pb-3">Jay Yogeshwar Petro Chemical</h4>
                        <p className="pl-0 md:pl-6 lg:pl-16 xl:pl-20">JAY YOGESHWAR PETRO CHEMICALS is a leading global provider for oil and gas services. We are specialist for Oil Refining, Oil Trading, Oil storage, Diesel trading, Shipping and Logistics.</p>
                        <div className="technology flex flex-wrap pl-0 md:pl-6 lg:pl-16 xl:pl-20 pt-7 lg:pt-8 xl:pt-8 2xl:pt-12">
                            <div className="image_part w-16">
                                <div className="thumb w-10 h-10 2xl:w-45 2xl:h-45 shadow-portfolio rounded-full p-0.5">
                                    <img src={TechnologyLogo} alt="portfolio"/>
                                </div>
                            </div>
                            <div className="content_part pt-2">
                                <h6 className="">Technology We used?</h6>
                                <div className="tech_logo flex flex-wrap pt-6">
                                    <img className="mr-6 h-10" src={AiLogo} alt="portfolio"/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="btn-group md:ml-6 lg:ml-16 xl:ml-20 mt-12 mb-3">
                            <Link className="btn bg-pink" title="Start Exploring" to="/contact">Start Exploring</Link>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="inner">
                        <h4 className="pl-0 md:pl-6 lg:pl-16 xl:pl-20 pb-1 sm:pb-2 md:pb-3 lg:pb-3 xl:pb-3 2xl:pb-3">Soni Incorporation</h4>
                        <p className="pl-0 md:pl-6 lg:pl-16 xl:pl-20">We soni incorporation is an Ahmedabad based company, we are highly qualified to be your manufacturing, design and supply chain partner we help in increase productivity by proving supply of projects, prototype and mass productions solutions with a wide range of industries with proven record. We proudly offer cost effective and high quality solutions for any type of orders.</p>
                        <div className="technology flex flex-wrap pl-0 md:pl-6 lg:pl-16 xl:pl-20 pt-7 lg:pt-8 xl:pt-8 2xl:pt-12">
                            <div className="image_part w-16">
                                <div className="thumb w-10 h-10 2xl:w-45 2xl:h-45 shadow-portfolio rounded-full p-0.5">
                                    <img src={TechnologyLogo} alt="portfolio"/>
                                </div>
                            </div>
                            <div className="content_part pt-2">
                                <h6 className="">Technology We used?</h6>
                                <div className="tech_logo flex flex-wrap pt-6">
                                    <img className="mr-6 h-10" src={WordpressLogo} alt="portfolio"/>
                                </div>
                            </div>
                        </div>
                      
                        <div className="btn-group md:ml-6 lg:ml-16 xl:ml-20 mt-12 mb-3">
                            <Link className="btn bg-pink" title="Start Exploring" to="/contact">Start Exploring</Link>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="inner">
                        <h4 className="pl-0 md:pl-6 lg:pl-16 xl:pl-20 pb-1 sm:pb-2 md:pb-3 lg:pb-3 xl:pb-3 2xl:pb-3">neighbourcare</h4>
                        <p className="pl-0 md:pl-6 lg:pl-16 xl:pl-20">NeighbourCare is a Newborn in the Industry and Growing rapidly to serve community people with vision to serve through their own ‘PrimaryCare Center’ comprise precise diagnosis to effective medicines at affordable price.</p>
                        <div className="technology flex flex-wrap pl-0 md:pl-6 lg:pl-16 xl:pl-20 pt-7 lg:pt-8 xl:pt-8 2xl:pt-12">
                            <div className="image_part w-16">
                                <div className="thumb w-10 h-10 2xl:w-45 2xl:h-45 shadow-portfolio rounded-full p-0.5">
                                    <img src={TechnologyLogo} alt="portfolio"/>
                                </div>
                            </div>
                            <div className="content_part pt-2">
                                <h6 className="">Technology We used?</h6>
                                <div className="tech_logo flex flex-wrap pt-6">
                                    <img className="mr-6 h-10" src={WordpressLogo} alt="portfolio"/>
                                    <img className="h-10" src={WoocommerceLogo} alt="portfolio"/>
                                </div>
                            </div>
                        </div>
                    
                        <div className="btn-group md:ml-6 lg:ml-16 xl:ml-20 mt-12 mb-3">
                            <Link className="btn bg-pink" title="Start Exploring" to="/contact">Start Exploring</Link>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    </div>
</div>
<img className="absolute right-0 transform  translate-y-2/4 z-1 bottom-m100 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="portfolio"/>
</section>
    );
  }
}








