import React, { useState } from 'react'
import FaviconIcon from '../images/about_shape.png'
import HomePageBanner from '../images/banner_image.png'
import HomePageShape from '../images/banner_shape.png'
import EwiseVideo from '../images/eWise.gif'
import AboutShape from '../images/about_shape.png'
import ServiceShape from '../images/service_shape.png'
import UserIcon from '../images/user.png'
import KenyaMap from '../images/kenya_map.svg'
import DubaiMap from '../images/dubai_map.svg'
import IndiaMap from '../images/india_map.svg'
import Location from '../images/pin.svg'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SimpleSlider from '../components/service-slick-slider'
import Portfolios from '../components/portfolio-slick-slider'
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import 'lazysizes'
import { withPrefix } from "gatsby"

const IndexPage = () => {

  const [tab, settab] = useState(0)
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/" />
          <link rel="icon" href={FaviconIcon} />
          <script src={withPrefix('custom-script.js')} type="text/javascript" />
          <script src={withPrefix('jquery.js')} type="text/javascript" />
        </Helmet>
      <Seo title="Home" description='eWise - Your end-to-end digital partner'/>

      <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
          <div className="-mx-4">
            <div className="banner_section flex flex-wrap items-center min-h-98 sm:min-h-98 md:min-h-98 lg:min-h-109 xl:min-h-113 2xl:min-h-113  flex-col lg:flex-row justify-center">
              <div className="order-2 sm:order-1 left_banner w-full sm:w-full  md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 px-4">
                <div className="inner text-center lg:text-left">
                  <div className="">
                    <h1 className="2xl:text-8xl font-normal">Your end-to-end <strong className="block relative xl:text-60 2xl:text-89 ">Partner <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-37 top-m3 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span> </strong></h1>
                    <p className="leading-1 text-sm sm:text-sm md:text-base lg:text-lg xl:text-22 2xl:text-3xl mb-5 sm:mb-5 md:mb-6 lg:mb-6 xl:mb-7 2xl:mb-10 mt-3 sm:mt-3 md:mt-4 lg:mt-4 xl:mt-5 2xl:mt-5">Always deliver more than expected</p>
                  </div>
                  <div className="btn-group">
                    <Link className="btn bg-pink" to="/contact" title="Start Exploring">Start Exploring</Link>
                  </div>
                </div>
              </div>
              <div className="mt-12 lg:mt-0 order-2 sm:order-2 right_banner w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 px-4">
                <div className="inner relative xl:p-12 2xl:p-0">
                  <img  className=" m-auto w-full sm:w-auto max-w-md lg:max-w-full lazyload" loading="lazy" data-src={HomePageBanner} alt="banner eWise"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img  className="absolute right-0 top-0 z-1" src={HomePageShape} alt="banner eWise" />
      </section>
              <section className="relative">
            <div className="container">
                <div className="-mx-4">
                    <div className="about_section flex flex-wrap items-center  xl:min-h-screen pt-0 pb-8 sm:pt-0 sm:pb-10  md:pt-0 lg:pt-14 lg:pb-14">
                        <div className="left_about w-full mb-10 lg:mb-0 lg:w-2/5 px-4 xl:pr-12 max-w-400 lg:max-w-full m-auto">
                            <div className="thumb relative pb-padding-130 xl:pb-padding-115">
                  <img className="md:m-auto lg:m-0 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full h-full object-cover lazyload" loading="lazy" data-src={EwiseVideo} alt="about eWise"/>
                            </div>
                        </div>
                        <div className="right_about w-full lg:w-3/5  pl-3 md:pl-4 lg:pl-8 xl:pl-16 pt-8 md:pt-0 px-4">
                            <h2 className="pb-4 lg:pb-4 xl:pb-6 2xl:pb-8"><strong className="inline-block relative ">eWise<span className="hidden xl:inline-block xl:top-m-20 xl:left-m-37 2xl:top-m-37 2xl:left-m-37 circlebig rounded-full absolute bg-blue z-1 lg:w-14 lg:h-14 xl:w-74 xl:h-74 2xl:w-107 2xl:h-107"></span></strong> is your trusted Web Development Partner!</h2>
                            <p className="">We offer web and mobile app development services to enterprises, with 100% project delivery success. We are the best web developers at affordable prices in the market. eWise's design-focused strategy and project execution processes
                                enable us to deliver the right solutions to you. <Link className="font-semibold" to="/about-us" title="Read More...">Read More...</Link> </p>
                                <h5 className='mt-10 mb-5'>we are happy to inform that we are Now </h5>
                                <div className='our_branches flex flex-wrap  sm:border-t sm:border-b sm:border-f2f2f2'>
                                  <div className='box w-full sm:w-33p text-center px-5 py-5 bg-transparent hover:bg-fbfbfb relative'>
                                    <img className='max-w-30 m-auto' src={Location} />
                                    <h6 className='mb-2 mt-3'>Dubai</h6>
                                    <span className='inline-block mb-2 text-sm leading-5'>(+971) 050 7185309</span>
                                    <p className='text-sm leading-5'>P2-ELOB Office NO. E2-110G-17 Al Hamriyah Free Zone - Sharjah Dubai, United Arab Emirates P.O Box: 0 HFZA, SHJ, UAE</p>
                                    <img className='absolute left-0 top-0 w-full h-full object-contain z-1' src={DubaiMap} />
                                  </div>
                                  <div className='box w-full sm:w-33p text-center px-5 py-5 bg-transparent hover:bg-fbfbfb border-b sm:border-b-0 border-t sm:border-t-0  sm:border-l sm:border-r border-f2f2f2 relative'>
                                  <img className='max-w-30 m-auto' src={Location} />
                                    <h6 className='mb-2 mt-3'>Kenya</h6>
                                    <span className='inline-block mb-2 text-sm leading-5'>(+254) 754333748</span>
                                    <p className='text-sm leading-5'>P.O. Box 41095 - 80100, Mombasa - Kenya Plot LR No. KAWALA "A" Kadzonzo -/399, KoKotoni, Kilifi Country</p>
                                    <img className='absolute left-0 top-0 w-full h-full object-contain z-1' src={KenyaMap} />
                                  </div>
                                  <div className='box w-full sm:w-33p text-center px-5 py-5 bg-transparent hover:bg-fbfbfb relative'>
                                  <img className='max-w-30 m-auto' src={Location} />
                                    <h6 className='mb-2 mt-3'>India</h6>
                                    <span className='inline-block mb-2 text-sm leading-5'>(+91) 8128531758</span>
                                    <p className='text-sm leading-5'>603, City Centre, Opp. Shukan Mall, Science City Road,Ahmedabad-380060</p>
                                    <img className='absolute left-0 top-0 w-full h-full object-contain z-1' src={IndiaMap} />
                                    
                                  </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <img className="absolute left-0 bottom-0 transform  translate-y-2/4 z-1 max-w-250 md:max-w-350 lg:max-w-350 xl:max-w-full" src={AboutShape} alt="about eWise"/>
        <img className="absolute right-0 transform  translate-y-2/4 z-1 bottom-m100 max-w-100 md:max-w-150 lg:max-w-150 xl:max-w-full" src={ServiceShape} alt="about eWise"/>
      </section>
      <section>
      <SimpleSlider>

      </SimpleSlider>
</section>
<section>
      <Portfolios>

      </Portfolios>
</section>

     

        <section className="pt-8 pb-8 sm:pt-10 sm:pb-10 lg:pt-14 lg:pb-14 xl:min-h-screen relative">
            <div className="container">
                <div className="-mx-4">
                    <div className="pt-8 pb-8 sm:pt-10 sm:pb-10 lg:pt-14 lg:pb-14 clients_Say xl:min-h-screen flex justify-items-center items-center">
                       
                        <div className="tabs flex flex-wrap ">
    
                            <div className="tabs-stage w-full md:w-3/5 px-4 flex-wrap flex item-center items-center">
                              <div id="tab-1">
                                <h3 className="relative pb-4 2xl:pb-6 mb-10 sm:mb-12 md:mb-12 xl:mb-12 2xl:mb-12">What<strong className="block">Clients Says</strong> <span className="absolute bottom-0 underline border-b-4 border-black w-32 inline-block"></span></h3>
                                <p className="">I would like to give my sincere thanks to the Ewise Solutions team for their outstanding commitment to our project. 
The team took the time to thoroughly understand the vision and the requirements of the project.</p>
                                    <h6 className="mt-8 xl:mt-10 relative lg:pl-4 xl:pl-4 2xl:pl-6">Ankit Jain<span className="hidden xl:block top-m-13 left-0 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-8 xl:h-8 2xl:w-45 2xl:h-45"></span></h6>
                                    <p className="text-base lg:pl-4 xl:pl-4 2xl:pl-6"><span className="w-6 h-0.5 bg-pink inline-block mb-1"></span> Managing Director</p>
                              </div>
                              <div id="tab-2">
                                <h3 className="relative pb-4 2xl:pb-6 mb-10 sm:mb-12 md:mb-12 xl:mb-12 2xl:mb-12">What<strong className="block">Clients Says</strong> <span className="absolute bottom-0 underline border-b-4 border-black w-32 inline-block"></span></h3>
                                <p className="">The Developer team is excellent and they do there job in a professional way and can handle all the jobs a signed to them. 
Communications and replays are fast and I realize that they work hard to finish the jobs on time.</p>
                                    <h6 className="mt-8 xl:mt-10 relative lg:pl-4 xl:pl-4 2xl:pl-6">Nilesh R<span className="hidden xl:block top-m-13 left-0 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-8 xl:h-8 2xl:w-45 2xl:h-45"></span></h6>
                                    <p className="text-base lg:pl-4 xl:pl-4 2xl:pl-6"><span className="w-6 h-0.5 bg-pink inline-block mb-1"></span> Managing Director</p>
                              </div>
                              <div id="tab-3">
                                <h3 className="relative pb-4 2xl:pb-6 mb-10 sm:mb-12 md:mb-12 xl:mb-12 2xl:mb-12">What<strong className="block">Clients Says</strong> <span className="absolute bottom-0 underline border-b-4 border-black w-32 inline-block"></span></h3>
                                <p className="">Ewsie Solutions was a company referred to me by a friend who had developed several apps with them.
I gave very little work to test their ability, they took the chance and created a fabulous Application.</p>
                                    <h6 className="mt-8 xl:mt-10 relative lg:pl-4 xl:pl-4 2xl:pl-6">Sorab Goyel<span className="hidden xl:block top-m-13 left-0 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-8 xl:h-8 2xl:w-45 2xl:h-45"></span></h6>
                                    <p className="text-base lg:pl-4 xl:pl-4 2xl:pl-6"><span className="w-6 h-0.5 bg-pink inline-block mb-1"></span> Managing Director</p>
                              </div>
                            </div>
                            <ul className="tabs-nav w-full md:w-2/5 px-4 flex  flex-wrap pt-10 sm:pt-10 justify-center sm:justify-center 2xl:justify-end 2xl:items-center">
                                <li className="transition duration-150 ease-in-out mx-6 sm:mx-6 relative md:mr-0 lg:mr-40 xl:mr-40 2xl:mr-56  flex justify-items-end  h-45 w-45 sm:w-45 sm:h-45 md:w-107 md:h-107 lg:w-107 lg:h-107 xl:w-32 xl:h-32 2xl:w-44 2xl:h-44 tab-active"><span className="active_circle active_circle1  lg:w-8 lg:h-8 xl:w-9 xl:h-9 2xl:w-45 2xl:h-45 bg-blue absolute rounded-full transform -translate-y-2\/4 lg:scale-0.8 lg:top-auto lg:left-m-36 lg:bottom-m-29  2xl:left-m-50"><span className="active_circle_in absolute lg:w-4 lg:h-4 xl:w-4 xl:h-4 lg:left-m-24 lg:top-m36 2xl:left-m-29 2xl:top-m44 2xl:w-26 2xl:h-26 bg-blue rounded-full"></span></span><a className="transition duration-150 ease-in-out rounded-full overflow-hidden w-45 sm:w-45 sm:h-45 md:w-107 md:h-107 lg:w-107 lg:h-107 xl:w-32 xl:h-32 2xl:w-44 2xl:h-44 flex justify-center items-center" href="#tab-1"><img className="w-full h-full object-cover  left-0 top-0 lazyload" loading="lazy"  data-src={UserIcon} alt="about ewise"/></a></li>
                                <li className="transition duration-150 ease-in-out mx-6 sm:mx-6 relative  lg:mr-0 xl:mr-10 sm:my-0 md:my-12  flex justify-items-end h-45  w-45 sm:w-45 sm:h-45 md:w-107 md:h-107 lg:w-107 lg:h-107 xl:w-32 xl:h-32 2xl:w-44 2xl:h-44"><span className="active_circle active_circle2  lg:w-8 lg:h-8 xl:w-9 xl:h-9 2xl:w-45 2xl:h-45 bg-blue absolute rounded-full top-1/2 transform -translate-y-2\/4 lg:scale-0.8 lg:left-m-68 2xl:left-m-80"><span className="active_circle_in absolute lg:w-4 lg:h-4 xl:w-4 xl:h-4 lg:top-m13 lg:left-m-39 2xl:w-26 2xl:h-26 bg-blue rounded-full"></span></span><a className="transition duration-150 ease-in-out rounded-full overflow-hidden w-45 sm:w-45 sm:h-45 md:w-107 md:h-107 lg:w-107 lg:h-107 xl:w-32 xl:h-32 2xl:w-44 2xl:h-44 flex justify-center items-center" href="#tab-2"><img className="w-full h-full object-cover  left-0 top-0 lazyload" data-src={UserIcon} alt="about ewise"/></a></li>
                                <li className="transition duration-150 ease-in-out mx-6 sm:mx-6 relative md:mr-0 lg:mr-40 xl:mr-40 2xl:mr-56  flex justify-items-end  h-45 w-45 sm:w-45 sm:h-45 md:w-107 md:h-107 lg:w-107 lg:h-107 xl:w-32 xl:h-32 2xl:w-44 2xl:h-44"><span className="active_circle active_circle3  lg:w-8 lg:h-8 xl:w-9 xl:h-9 2xl:w-45 2xl:h-45 bg-blue absolute rounded-full  transform -translate-y-2\/4 lg:scale-0.8  lg:left-m-50 lg:top-auto "><span className="active_circle_in absolute lg:w-4 lg:h-4 xl:w-4 xl:h-4 lg:bottom-m36 lg:left-m-24 2xl:bottom-m44 2xl:left-m-29 transform 2xl:w-26 2xl:h-26 bg-blue rounded-full"></span></span><a className="transition duration-150 ease-in-out rounded-full overflow-hidden w-45 sm:w-45 sm:h-45 md:w-107 md:h-107 lg:w-107 lg:h-107 xl:w-32 xl:h-32 2xl:w-44 2xl:h-44 flex justify-center items-center" href="#tab-3"><img className="w-full h-full object-cover  left-0 top-0 lazyload" data-src={UserIcon} alt="about ewise"/></a></li>
                              </ul>
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default IndexPage