import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShopifyLogo from '../images/shopify.svg'
import LaravelLogo from '../images/laravel.svg'
import WordpressLogo from '../images/wordpress.svg'
import CodeigniterLogo from '../images/codeigniter.svg'
import PhpLogo from '../images/php.svg'
import MagentoLogo from '../images/magento.svg'
import AndroidLogo from '../images/android.svg'
import AppleLogo from '../images/apple.svg'
import FlutterLogo from '../images/flutter.svg'
import GraphicLogo from '../images/graphic_logo.svg'
import QaLogo from '../images/qa_logo.svg'
import DigitalMarketingLogo from '../images/digital_marketing_logo.svg'
import {Link } from 'gatsby'

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1466,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        }, {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
         {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }, {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    
  };
  return (
<div className="">
  <div className="slick_slider_dots service_section xl:min-h-screen pt-8 pb-8 sm:pt-10 sm:pb-10 lg:pt-14 lg:pb-14 overflow-hidden">
    <div className="container">
      <div className="title -mb-6 md:-mb-6 lg:-mb-5">
        <h3 className="relative pb-4 2xl:pb-6 mb-0 2xl:mb-8">Our<strong className="block">Services</strong> <span className="absolute bottom-0 underline border-b-4 border-black w-32 inline-block"></span></h3>
      </div>
    </div>
    <div className="pr-4 sm:pr-padding-left-sm md:pr-padding-left-md lg:pr-padding-left-lg  xl:pr-0  pl-4 sm:pl-padding-left-sm md:pl-padding-left-md lg:pl-padding-left-lg xl:pl-padding-left-xl 2xl:pl-padding-left-2xl">
        <div className="minus_slide">
      <Slider className="service_slider_wrapper" {...settings}>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={ShopifyLogo} alt="Shopify" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Shopify Development" to="/shopify-web-development-services">Shopify <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">Our certified developers and designers deliver a holistic and effective e-commerce website strategy, products and marketing tactics, design and usability, technology and security. This ensures that you achieve your sales
              and business goals.(Ex: Shopify, Magneto)</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={LaravelLogo} alt="Laravel" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Laravel Development" to="/laravel-web-development-services">Laravel <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">With the unparalleled expertise in working with the latest web technologies, we deliver custom web applications for enterprises. Whether it comes to create a web presence for your company, an information hub for your business or enabling your apps/IoT devices through the backend! (Ex: PHP, Wordpress, Laravel, Codeigniter)</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={WordpressLogo} alt="Wordpress" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Wordpress Development" to="/wordpress-web-development-services">Wordpress <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We offer native Android app development, native iOS app development, hybrid and cross-platform app development services to build next-gen mobile applications using the latest technologies.(Ex: Flutter, iOS, Android)</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={CodeigniterLogo} alt="Codeigniter" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Codeigniter Development" to="/codeigniter-web-development-services">Codeigniter <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">Our CMS development enables you to deploy unique content management demands, user profiles, custom modules, themes and campaigns, making sure of smooth functioning of your business workflows.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={PhpLogo} alt="PHP" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="PHP Development" to="/php-web-development-services">PHP <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We are a leading web design and mobile app design company with a knack of turning imaginations into reality. Right from conceptualization to UX design, we strive for quality and timely delivery of engaging and beautiful websites and mobile apps.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={MagentoLogo} alt="Magento" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Magento Development" to="/magento-web-development-services">Magento <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We enable you to promote and sell products and services by leveraging online marketing tactics such as social media marketing, search marketing, and email marketing. Right from content to design, we offer an holistic and customized digital marketing service.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={AndroidLogo} alt="Android" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Android Development" to="/android-app-development-services">Android <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">Our professional designers have the knowledge of marketing and branding, and ensure that the design we create seamlessly goes with your brand value and messaging. We create beautiful designs and graphics, keeping your business goals in mind.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={AppleLogo} alt="ios" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="ios Development" to="/ios-app-development-services">ios <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We have a proven testing expertise that enables you speedy time to market with uncompromised quality. We provide various testing services such as Functional, GUI, Usability, Security, Cross-platform, Cross-browser, Accessibility, etc.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={FlutterLogo} alt="Flutter" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Flutter Development" to="/flutter-app-development-services">Flutter <br/> Development</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We have a proven testing expertise that enables you speedy time to market with uncompromised quality. We provide various testing services such as Functional, GUI, Usability, Security, Cross-platform, Cross-browser, Accessibility, etc.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={GraphicLogo} alt="Graphic Design" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Graphic Design" to="/graphics-design-services">Graphic <br/> Design</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We have a proven testing expertise that enables you speedy time to market with uncompromised quality. We provide various testing services such as Functional, GUI, Usability, Security, Cross-platform, Cross-browser, Accessibility, etc.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={QaLogo} alt="Quality Analysis" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Quality Analysis" to="/quality-analysis">Quality <br/> Analysis</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We have a proven testing expertise that enables you speedy time to market with uncompromised quality. We provide various testing services such as Functional, GUI, Usability, Security, Cross-platform, Cross-browser, Accessibility, etc.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        <div className="box pr-10 2xl:pr-14 pt-20 pb-20 h-inherit">
          <div className="inner group bg-white shadow-slider p-6 sm:p-9 text-center relative h-100">
            <img className="m-auto max-w-45" src={DigitalMarketingLogo} alt="Digital Marketing" />
            <h6 className="pt-5 pb-4 2xl:pb-6"><Link className="group-hover:text-pink text-black transition duration-150 ease-in-out" title="Digital Marketing" to="/digital-marketing">Digital <br/> Marketing</Link></h6>
            <p className="leading-6 text-sm 2xl:leading-7 2xl:text-base">We have a proven testing expertise that enables you speedy time to market with uncompromised quality. We provide various testing services such as Functional, GUI, Usability, Security, Cross-platform, Cross-browser, Accessibility, etc.</p>
            <div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
          </div>
        </div>
        </Slider>
        </div>
    </div>
    <div className="service_dots_wrapper"></div>
  </div>
</div>
  );
}







